import React from 'react'
import { userSession } from 'utils/auth'
import { Redirect } from '@reach/router'
import SignIn from 'src/app/SignIn'
import Homepage from 'src/app/Homepage'
export default () => {
  const handleSignIn = e => {
    e.preventDefault()
    userSession.redirectToSignIn()
  }

  const handleSignOut = e => {
    e.preventDefault()
    userSession.signUserOut(window.location.origin)
  }

  if (userSession.isUserSignedIn()) {
    return <Redirect to="/app" noThrow />
  }
  return <Homepage userSession={userSession} handleSignIn={handleSignIn} />
}
