import React from 'react'
import { Link } from '@reach/router'
import cn from 'classnames'
import css from './style.module.scss'

const Button = ({
  to,
  children,
  onClick,
  className,
  type,
  link = false,
  secondary,
  big,
  small,
  external,
  nomargin,
  loading,
  disabled,
  alt,
  style,
}) => {
  const classNames = cn(
    link ? css.link : css.button,
    secondary && css.secondary,
    big && css.big,
    small && css.small,
    nomargin && css.nomargin,
    disabled && css.disabled,
    className
  )

  if (to) {
    if (external || to.startsWith('http')) {
      return (
        <a
          href={to}
          style={style}
          alt={alt}
          target="_blank"
          className={classNames}
        >
          {children}
        </a>
      )
    }
    return (
      <Link style={style} className={classNames} alt={alt} to={to}>
        {children}
      </Link>
    )
  }
  return (
    <button
      type={type || 'button'}
      className={classNames}
      style={style}
      alt={alt}
      onClick={!loading && !disabled ? onClick : undefined}
    >
      {loading ? 'Loading...' : children}
    </button>
  )
}

export default Button
